import { startCase } from "lodash";
import { DEFAULT_DOMAIN_NAME, DEFAULT_FAVICON } from "@shared/utils/constants";
import { ROUTE_TO_PAGE_NAME_MAP } from "./constants";

export const setBrandedTitleAndIcon = (title, getters) => {
    const tenant = {
        domain_name: getters?.getTenantInfo?.domain_name || DEFAULT_DOMAIN_NAME,
        logo_url: getters?.getTenantInfo?.logo_url || getters?.getThemeLogo,
    };

    const logoElement = document.querySelector("link[rel~='icon']");

    document.title = `${title} | ${startCase(tenant.domain_name)}`;

    logoElement.href = tenant?.logo_url || DEFAULT_FAVICON;
};

export const getPageTitle = (route) => {
    const { name, params } = route ?? {};
    const queryField = ROUTE_TO_PAGE_NAME_MAP[name]?.queryField;

    if (queryField) {
        return params[queryField];
    }

    if (name in ROUTE_TO_PAGE_NAME_MAP) {
        return ROUTE_TO_PAGE_NAME_MAP[name].name;
    }

    return name;
};

/**
 * This function prevents strange behavior of the system in a case
 * when the tenant logo_url doesn't set and the client icon is applied
 * instead of the default
 */
export const forceSetTenantLogoToFavIcon = (getters) => {
    const logoElement = document.querySelector("link[rel~='icon']");

    const observer = new MutationObserver((mutationList) => {
        for (const { type, target, attributeName } of mutationList) {
            if (type !== "attributes") continue;

            const value = target.getAttribute(attributeName);
            if (value !== DEFAULT_FAVICON && !getters.getTenantInfo?.logo_url) {
                logoElement.href = DEFAULT_FAVICON;
            }
        }
    });

    observer.observe(logoElement, { attributes: true });
};
