const EXCLUDED_ROUTES = ["Login"];

const ROUTE_TO_PAGE_NAME_MAP = Object.freeze({
    "Profile Container": { queryField: "caseid" },
    "Create Case": { name: "Case Creation" },
    "Group Details": { name: "Groups" },
    "User Details": { name: "Users" },
    "Credit Packs": { name: "Credits Pack" },
    "purchaseOrderList": { name: "Purchase Orders" },
    "allotedcredit": { name: "Allocated Credit" },
    "license-list": { name: "License" },
    "license-add": { name: "Add License" },
    "license-details": { name: "License Details" },
});

export { EXCLUDED_ROUTES, ROUTE_TO_PAGE_NAME_MAP };
