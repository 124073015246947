//  Import Routes
// import { ProfileRoutes } from "./modules/profile/routes";
import Vue from "vue";
import VueRouter from "vue-router";
import VueCookies from "vue-cookies";
import { validateAndSetAuth } from "@/modules/auth/services";
import { FormsPreview } from "@/modules/Forms-Preview/routes.js";
import { LightRayRoutes } from "@shared/light-ray/routes"
import { License } from '@/modules/license/routes.js'
// import { checkPermission } from "@shared/utils/functions"

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "dashboard.view",
        component: () => import(/* webpackChunkName: "dashboard.view" */ "@/pages/dashboard"),
        redirect: { name: "Case Dashboard" },
        children: [
            {
                // to be changed to be something more logical according to route
                path: "/case-dashboard",
                name: "Case Dashboard",
                component: () => import(/* webpackChunkName: "case-dashboard" */ "@/pages/dashboard/pages/case-dashboard"),
                meta: {
                    title: "Dashboard",
                },
            },
            {
                // to be changed to be something more logical according to route
                path: "/2",
                name: "Dashboard - Page 2",
                component: () => import(/* webpackChunkName: "case-dashboard-page2" */ "../pages/dashboard/pages/page2"),
            },
            // {
            //   name: "Fields",
            //   path: "/field-table",
            //   component: () =>
            //     import(/* webpackChunkName: "field-table" */ "../pages/field-table"),
            //   meta: {
            //     breadcrumb: [
            //       { name: 'Admin' },
            //       { name: 'Fields List' }
            //     ]
            //   }
            // },
            // {
            //   name: "Field Setting",
            //   path: "/field-settings/:id",
            //   component: () =>
            //     import(
            //       /* webpackChunkName: "field-settings" */ "../pages/field-table/fieldTableDataView"
            //     ),
            //   meta: {
            //     breadcrumb: [
            //       { name: 'Admin' },
            //       { name: 'Field List' },
            //       { name: 'Field Info' },
            //     ]
            //   },
            //   props: true,
            // },
            {
                name: "Create package",
                path: "/create-package",
                component: () => import(/* webpackChunkName: "create-package" */ "../pages/create-package"),
                props: true,
            },
            {
                name: "Add Field",
                path: "/add-field/:field?",
                component: () => import(/* webpackChunkName: "add-field" */ "../pages/field-table/fieldTableDataView"),
                meta: {
                    breadcrumb: [{ name: "Field List" }, { name: "Create Field" }],
                },
                props: true,
            },
            // NOTE: FIX THIS
            // MAKE THE ROUTES OF SAME COMPONENTS CHILD ROUTES
            {
                name: "check Admin",
                path: "/check-admin",
                component: () => import(/* webpackChunkName: "check-admin" */ "../pages/check-admin/check-admin"),
                meta: {
                    breadcrumb: [{ name: "Checks  List" }],
                },
            },
            {
                name: "check admin details",
                path: "/check-admin/view/:id/setting",
                component: () => import(/* webpackChunkName: "company-admin-view-settings" */ "../pages/check-admin/check-dragable-section"),
                meta: {
                    breadcrumb: [{ name: "Admin" }, { name: "Checks  List" }, { name: "Check  Info" }, { name: "Check  Edit" }],
                },
                // component: () => import("../pages/check-admin/check-dragable-section-dragable-field"),
            },
            {
                name: "check View",
                path: "/check-admin/view/:id",
                component: () => import(/* webpackChunkName: "check-admin-view" */ "../pages/check-admin/check-view"),
                meta: {
                    breadcrumb: [{ name: "Admin" }, { name: "Checks  List" }, { name: "Check  Info" }],
                },
            },
            // NOTE: FIX THIS
            // MAKE THE ROUTES OF SAME COMPONENTS CHILD ROUTES
            {
                name: "User Details",
                path: "/users",
                component: () => import(/* webpackChunkName: "user-details" */ "../pages/user-details"),
                meta: {
                    title: "Users",
                    breadcrumb:[{ name: "Admin" }, { name: "Users" }],
                },
            },
            {
                name: "User Setting",
                path: "/users/:id",
                component: () => import(/* webpackChunkName: "user-settings" */ "../pages/user-details/userTableDataView"),
                props: true,
                meta: {
                    title: "Users",
                    breadcrumb:[{ name: "Admin" }, { name: "Users" }, {name: 'Details'}],
                },
            },
            {
                name: "Group Details",
                path: "/groups",
                component: () => import(/* webpackChunkName: "group-details-group" */ "../pages/group-details"),
                meta: {
                    title: "Groups",
                    breadcrumb:[{ name: "Admin" }, { name: "Groups" }],
                },
            },
            {
                name: "Group Setting",
                path: "/group-settings/:id",
                component: () => import(/* webpackChunkName: "group-settings-client" */ "../pages/group-details/groupTableDataView"),
                props: true,
                meta: {
                    title: "Groups",
                    breadcrumb:[{ name: "Admin" }, { name: "Groups" }, {name: 'Details'}],
                },
            },
            {
                name: "Profile Container",
                path: "/case/:caseid/profile-view",
                component: () => import(/* webpackChunkName: "profile-container-client" */ "../pages/profile-view"),
                meta: {
                    showCaseInfo: true,
                },
            },
            {
                name: "Report template",
                path: "/case/:caseid/profile-view/outputs",
                component: () => import(/* webpackChunkName: "profile-container-client" */ "../pages/profile-view"),
                meta: {
                    showCaseInfo: true,
                },
            },
        
            {
                name: "pending-activity",
                path: "/activity",
                component: () => import(/* webpackChunkName: "pending-activity" */ "@/pages/activity-history-notification"),
                meta:{
                    title: "Pending Activities",
                }
            },
            {
                name: "Screening Package",
                path: "/packages",
                component: () => import(/* webpackChunkName: "screening-packages" */ "../pages/tenant-packages"),
                meta: {
                    breadcrumb: [{ name: "Packages  List" }],
                },
            },
            // {
            //   name: "Create Case Form",
            //   path: "/create-case-form/:id",
            //   component: () =>
            //     import(
            //       /* webpackChunkName: "case-creation-form" */ "../pages/create-case/createCaseForm"
            //     ),
            // },

            {
                name: "Create Case",
                path: "/create-case",
                component: () => import(/* webpackChunkName: "create-case" */ "../pages/create-case"),
            },

            {
                name: "Clients List",
                path: "/clients-list",
                component: () => import(/* webpackChunkName: "client-list" */ "../pages/clients-list"),
                meta: {
                    breadcrumb: [{ name: "Client  List" }],
                },
            },

            {
                name: "Create Client",
                path: "/create-client",
                component: () => import(/* webpackChunkName: "create-client" */ "../pages/create-client"),
                meta: {
                    breadcrumb: [{ name: "Client  List" }],
                },
            },
            {
                name: "Credit Packs",
                path: "/credit-packs",
                component: () => import(/* webpackChunkName: "user-details" */ "../pages/credit-packs"),
                meta: {
                    title: "Credit Admin",
                    breadcrumb:[{ name: "Client Admin" }, { name: "Credit Pack" }],
                },
            },
            {
                name: "Allocate Credits",
                path: "/allocate-credits",
                component: () => import(/* webpackChunkName: "user-details" */ "../pages/credit-packs/pack-details"),
                meta: {
                    title: "Credit Admin",
                    breadcrumb:[{ name: "Client Admin" }, { name: "Credit Pack" }, { name: "Allotted Credit Pack" }],
                },
            },
            {
                name: "allotedcredit",
                path: "/allocate-credits/:id",
                component: () => import(/* webpackChunkName: "user-details" */ "../pages/credit-packs/alloted-credits"),
                meta: {
                    title: "Credit Admin",
                    breadcrumb:[{ name: "Client Admin" }, { name: "Credit Pack" }, { name: "Modify Allocate Credit Pack" }],
                },
            },
            {
                name: "createusage",
                path: "/create-usage/",
                component: () => import(/* webpackChunkName: "user-details" */ "../pages/credit-packs/create-usage"),
                meta: {
                    title: "Credit Admin",
                    breadcrumb:[{ name: "Client Admin" }, { name: "Credit Pack" }, { name: "Create Source Usage" }],
                },
            },
            {
                name: "sourceUsageDetails",
                path: "/source-usage-details/:id",
                component: () => import(/* webpackChunkName: "user-details" */ "../pages/credit-packs/source-usage-details"),
                meta: {
                    title: "Credit Admin",
                    breadcrumb:[{ name: "Client Admin" }, { name: "Credit Pack" }, { name: "Source Usage Details" }],
                },
            },
            {
                name: "inventoryDetails",
                path: "/inventory-details/:id",
                component: () => import(/* webpackChunkName: "user-details" */ "../pages/credit-packs/inventory-details"),
                meta: {
                    title: "Credit Admin",
                    breadcrumb:[{ name: "Client Admin" }, { name: "Credit Pack" }, { name: "Inventory" }],
                },
            },
            {
                name: "creditPackDetails",
                path: "/credit-pack-details/:id",
                component: () => import(/* webpackChunkName: "user-details" */ "../pages/credit-packs/credit-pack-details"),
                meta: {
                    title: "Credit Admin",
                    breadcrumb:[{ name: "Client Admin" }, { name: "Credit Pack" }, { name: "Credit Pack Details" }],
                },
            },
            // {
            //   name: "Edit Client",
            //   path: "/client/:id",
            //   component: () => import("../pages/create-client"),
            //   meta: {
            //     breadcrumb: [
            //       { name: 'Admin' },
            //       { name: 'Client  List' },
            //       { name: 'Client  Info' },
            //     ]
            //   },
            // },

            {
                name: "Screening Package View",
                path: "/packages/:packageName",
                component: () => import(/* webpackChunkName: "screening-packages-view" */ "../pages/tenant-packages/package-details"),
                meta: {
                    breadcrumb: [{ name: "Package  List" }, { name: "Package  Info" }],
                },
                children: [
                    {
                        path: ":packagetool?",
                        name: "Package Tools",
                        meta: {
                            breadcrumb: [{ name: "Package  List" }],
                            customCrumbs: [],
                        },
                    },
                ],
            },
            {
                name: "purchaseOrderList",
                path: '/purchase-orders',
                component: () => import(/* webpackChunkName: "purchaseOrders" */ "../pages/purchase-orders/order-list"),
                meta: {
                    breadcrumb: [{ name: "Client Admin" }, { name: "Purchase Orders" }],
                }
            },
            {
                name: "PurchaseOrderDetail",
                path: '/order-info/:id?/:client_name?',
                component: () => import(/* webpackChunkName: "createPurchaseOrders" */ "../pages/purchase-orders/create-order/create-order"),
                meta: {
                    breadcrumb: [{ name: "Client Admin" }, { name: "Purchase Orders" }, { name: "Create Order" }],
                }
            },
              ...License,
              ...LightRayRoutes
        ],
    },
    // {
    //     path: "/login/:prefix",
    //     name: "Login ID",
    //     component: () => import(/* webpackChunkName: "login" */ "@/modules/auth/login-id"),
    // },
    {
        path: "/login",
        name: "Login",
        component: () => import(/* webpackChunkName: "login" */ "@/modules/auth"),
    },
    {
        name: "Tools",
        path: "/tools",
        component: () => import(/* webpackChunkName: "tools" */ "../pages/tools"),
    },
    {
        name: "Email Template Admin",
        path: "/email-template-admin",
        component: () => import(/* webpackChunkName: "email-template-admin" */ "../pages/email-template"),
    },
    {
        name: "Email Template",
        path: "/email-template-admin/:folder",
        component: () => import(/* webpackChunkName: "email-template-admin-view" */ "../pages/email-template/email-lists"),
    },
    // {
    //     name: "LoginCallback",
    //     path: "/login-success",
    //     component: () => import(/* webpackChunkName: "login-callback-client" */ "../modules/auth/login-success"),
    //     props: true,
    // },
    {
        name: "Form Builder",
        path: "/form/preview/:package/:role",
        component: () => import(/* webpackChunkName: "form-builder-client" */ "../pages/form-builder"),
    },
    {
        name: "SC Journey",
        path: "/sc-journey",
        component: () => import(/* webpackChunkName: "sc-journey-client" */ "../pages/sc-journey"),
    },
    {
        name: "not-found",
        path: "*",
        component: () => import(/* webpackChunkName: "not-found-client" */ "../pages/not-found"),
    },
    {
        name: "Invalid",
        path: "/invalid",
        component: () => import(/* webpackChunkName: "invalid-client" */ "@shared/invalid"),
    },
    {
        path: "/idcheck-confirmation",
        name: "IDCheckConfirmation",
        component: () => import(/* webpackChunkName: "idcheck-confirmation-client" */ "@shared/idcheck-confirmation"),
    },
    
    // {
    //     name: "purchaseOrderList",
    //     path: '/purchase-orders',
    //     component: () => import(/* webpackChunkName: "purchaseOrders" */ "../pages/purchase-order"),
    //     meta: {
    //         breadcrumb: [{ name: "Admin" }, { name: "Purchase Orders" }],
    //     }
    // },
  
    // Module Routes
    ...FormsPreview,
    
];

const router = new VueRouter({
    // base:process.env.VUE_APP_BASE_URL_CLIENT,
    routes,
    mode: "history",
});

router.onError((error) => {
    if (/loading chunk \d* failed./i.test(error.message)) {
        window.location.reload();
    }
});

/// AUTH MIDDLEWARE

router.beforeEach(async (to, from, next) => {
    // checkPermission;
    console.log("url",LightRayRoutes)
    if (to.name.includes("form-delvium")) {
        next();
        return;
    }

    const token = VueCookies.get("token");
    if (token && from.name === null) {
        // if user directly came to any specific url
        await validateAndSetAuth(token);
        // interceptResponse();
        next();
    }
    if (!token) {
        if (to.name === "LoginCallback" || to.name === "Login" || to.name === "Login ID") {
            next();
        } else {
            // If not logged in
            // Trying to navigate to another page, send to login but keep next-url.
            // VueCookies.set("next-url", to.fullPath)
            if (to.fullPath === "/" && !VueCookies.get("next-url").includes("#fileManager")) 
                VueCookies.set("next-url", "/case-dashboard");
            else VueCookies.set("next-url", to.fullPath);
            next({ name: "Login" });
        }
        next();
    } else {
        if (to.name === "LoginCallback" || to.name === "Login" || to.name === "Login ID" || to.name === "dashboard") {
            // Trying to open the Login page, you be crazy!
            // interceptResponse();
            next({ name: "dashboard.view" });
        } else {
            // All systems good to go!
            // interceptResponse();
            next();
        }
    }
});
export default router;
