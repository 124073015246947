import Vue from "vue";
import axios from "@/axios";
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

const options = {
    // You can set your default options here
};
Vue.use(Toast, options);

export const ProfileStore = {

    state: {
        refChecks: []
    },

    mutations: {
        SET_REF_CHECKS: (state, payload) => {
            state.refChecks = payload;
        },
    },

    getters: {
        getRefChecks: state => state.refChecks,
    },

    actions: {
        fetchRefChecks: async ({ commit }, payload) => {
            console.log(payload, "map action s payload")
            const case_id = payload
            try {
                const { data } = await axios(`case/${case_id}/check/client`)
                data.data = data.data.map(el => {
                    if (el.check_category == 'Non Reference') {
                        let obj = el
                        obj.references = [{
                            status_color: el.stage_color || 'blue',
                            stage_id: el.stage_id,
                            status_name: el.stage_name
                        }]
                        return obj 
                    } else return el
                })
                console.log('data.data :>> ', data.data);
                commit('SET_REF_CHECKS', data.data)
            } catch (error) {
                Vue.$toast.warning(error.response.data.error || "ref checks not found!");
            }
        },

    }

};
