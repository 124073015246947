import axios from "./axios";

export class FormsPreviewServ {
    static async fetchForms({ type }) {
        let formError = false;
        try {
            const { data } = await axios.get(`/case/additional-forms/${type}?is_additional_form=${true}`);
            console.log("step-1",data);
            return data;
        } catch (error) {
            formError = {error: true, errorMessage: error?.response?.data?.detail};
            console.log(error, "[+]Form is already submitted");
            return formError;
        }
    }
    static async validateToken({ token }) {
        try {
            const { data } = await axios.post("/form-auth/validate", {
                link_identifier: token,
            });
            return data;
        } catch (error) {
            console.log(error, "[+]failed validate token");
        }
    }
    static async fetchRefreshToken(token) {
        const url = `/form-auth/token`;
        try {
            // let config = {
            //     headers: {
            //         Authorization: 'Bearer ' + token
            //     }
            // }
            let response = await axios.post(url, { link_identifier: token });
            return response.data.access_token;
        } catch (error) {
            console.error(error);
            throw new Error("Refresh token failed from server!");
        }
    }
}
