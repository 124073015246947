import { setBrandedTitleAndIcon, getPageTitle, forceSetTenantLogoToFavIcon } from "./utils";
import { EXCLUDED_ROUTES } from "./constants";

export default {
    install (Vue, store, router) {
        router.beforeEach(async (to, from, next) => {
            if (!store.getters.getTenantInfo && !EXCLUDED_ROUTES.includes(to.name)) {
               await store.dispatch("fetchTenantInfo");
            }

            const pageTitle = getPageTitle(to);
            setBrandedTitleAndIcon(pageTitle, store.getters);

            next();
        });

        forceSetTenantLogoToFavIcon(store.getters);

        Vue.prototype.$brandPage = function (route) {
            const pageTitle = getPageTitle(route);
            setBrandedTitleAndIcon(pageTitle, store.getters);
        }
    }
}
