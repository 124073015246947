import { default as ax } from "axios";
import VueCookies from "vue-cookies";

// import store from "@/store";
// import { APP_CONFIG } from "@/utils/constants";
import refreshTokeninterceptor from "./utils.js";

const axios = ax.create({
    baseURL: `/api/v1`,
});

axios.interceptors.request.use(
    (config) => {
        let token = VueCookies.get("additional-form-token");
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(undefined, refreshTokeninterceptor(axios));
export default axios;
