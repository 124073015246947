import Vue from "vue";
import Vuex from "vuex";
import axios from "@/axios";
import VueCookies from "vue-cookies";
import { AuthStore } from "@/modules/auth/store";
import { FieldsAminStore } from "@/modules/fields-admin/store";
import { PackageStore } from "@/modules/packages/store"
import { CaseStore } from "@/modules/case/store";
import ThemeStore from "@/modules/theme/store";
import { ProfileStore } from "@/modules/profile/store";
import { DashboardStore } from "@/modules/dashboard/store"
import { FormsPreview } from "@/modules/Forms-Preview/store"
import { LightRayStore } from "../../../shared/light-ray/store";
import { Store } from "../../../shared/store"

Vue.use(Vuex);

export const RootStore = {
  state: {
    // cleint_id: null,
    isNavCollapsed: window.localStorage.getItem("nav_collapsed"),
    tenantInfo: null,
  },
  getters: {
    getTenantId: (state) => state.tenant_id,
    getNavCollapsed: (state) => state.isNavCollapsed,
    getTenantInfo: (state) => state.tenantInfo
  },
  mutations: {
    SET_NAV_COLLAPSE(state, payload) {
      state.isNavCollapsed = payload;
    },
    SET_TENANT_INFO(state, payload) {
      state.tenantInfo = payload;
    },
    // SET_CLIENT_ID(state, payload) {
    //   state.client_id = payload;
    // },
  },
  actions: {
    setNavCollapsed({ commit }, payload) {
      window.localStorage.setItem("nav_collapsed", payload);
      commit("SET_NAV_COLLAPSE", payload);
    },
    async fetchTenantId({ commit }) {
      try {
        const { data } = await axios.post(`client-auth/init`, {});
        if (data) {
          console.log("fghjk")
          commit("SET_USER", data.user_details)
          if (data.client_id && data.client_details) {
            commit("SET_CLIENT_TRUE")
            VueCookies.set("isClient",true)
          }
          else {
            commit("SET_TENANT_TRUE")
            VueCookies.set("isClient",false)
          }
        }
      } catch (error) {
        console.log(error.message || `ERROR FETCHING CLIENT ID`);
      }
    },
    async fetchTenantInfo({ commit }) {
      try {
        const { data } = await axios.get("/tenant/get-tenant-information");

        if (data?.data) {
            commit("SET_TENANT_INFO", data.data);
        }
      } catch (error) {
        console.log(error?.message || `ERROR FETCHING TENANT INFO`);
      }
    },
    // setTenantId({ commit }, payload) {
    //   commit("SET_CLIENT_ID", payload);
    // },
  },
  modules: {
    auth: {
      ...AuthStore,
    },
    fieldsAdmin: { ...FieldsAminStore },
    CaseStore: { ...CaseStore },
    PackageStore: {...PackageStore},
    ProfileStore: {...ProfileStore},
    DashboardStore: {...DashboardStore},
    FormsPreview: {...FormsPreview},
    ThemeStore: {...ThemeStore},
    LightRayStore:{...LightRayStore},
    Store: {...Store},
  },
};

const store = new Vuex.Store(RootStore);

export default store;
