export const FormsPreview = [
    {
        path: "/form/:type/:token",
        // name: "form-delvium-index",
        component: () => import(/* webpackChunkName: "form-delvium-index" */ "./index.vue"),
        children: [
            {
                path: "",
                name: "form-delvium-index",
                component: () => import(/* webpackChunkName: "form-delvium" */ "./pages/Form-Preview.vue"),
            },
        ],
    },
    {
        path: "/additional-form/type/:type",
        name: "form-delvium-additional-form",
        component: () => import(/* webpackChunkName: "additional-form-delvium" */ "./pages/Additional-Form.vue"),
    },
];
