import { default as ax } from "axios";
import VueCookies from "vue-cookies";

// import store from "@/store";
// import { APP_CONFIG } from "@/utils/constants";
import refreshTokeninterceptor from "./utils.js";
import {getBaseUrlDelviumBackend} from "@shared/utils/functions"

let axios = ax.create({
    baseURL: getBaseUrlDelviumBackend(),
});

axios.interceptors.request.use(
    (config) => {
        let token = VueCookies.get("token") || VueCookies.get("additional-form-token");
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
            /*
            This function shouldn't be required at all , BUT is used for now!
            Reason: Initially there was no concept of user/account in saas app.
            so hardcoded userdid /apss was sent(to test atleast), but later on
            jwt based token with our own iAM server & Miniorange was integrated into backend.

            To keep some apis working ex(case list api) which dependes upon some header values,
            use this function.
            */
            // config.headers["X-Source"] = `(saas-platform) ${APP_CONFIG.name}/${APP_CONFIG.version}`;
            // config.headers["X-User-ID"] = store.getters.getUserAuthDetails.userId;
            // config.headers["X-CASE-ID"] = store.getters.getCaseId == null ? "" : store.getters.getCaseId;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(undefined, refreshTokeninterceptor(axios));
export default axios;
