import { fetchRefreshToken } from "@/modules/auth/services";
import store from "@/store";
import VueCookies from "vue-cookies";

// for multiple requests
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};

const refreshTokeninterceptor = (axiosInstance) => (error) => {
    const _axios = axiosInstance;
    const originalRequest = error.config;
    const { status } = error.response;

    if ((status === 403 || status === 401) && !originalRequest._retry) {
        if (isRefreshing) {
            return new Promise(function (resolve, reject) {
                failedQueue.push({ resolve, reject });
            })
                .then((token) => {
                    originalRequest.headers["Authorization"] = "Bearer " + token;
                    return _axios.request(originalRequest);
                })
                .catch((err) => {
                    return Promise.reject(err);
                });
        }
        console.log("!!!INSIDE!!!");
        originalRequest._retry = true;
        isRefreshing = true;

        const tokenToRefresh = VueCookies.get("token");
        const refreshToken = VueCookies.get("refresh_token")
        return new Promise((resolve, reject) => {
            fetchRefreshToken(tokenToRefresh, refreshToken)
                .then((token) => {
                    VueCookies.set("token", token);
                    _axios.defaults.headers.common["Authorization"] = "Bearer " + token;
                    originalRequest.headers["Authorization"] = "Bearer " + token;
                    processQueue(null, token);
                    resolve(_axios(originalRequest));
                })
                .catch((err) => {
                    processQueue(err, null);
                    reject(err);
                    store.dispatch("logoutUser");
                })
                .then(() => {
                    isRefreshing = false;
                });
        });
    }

    return Promise.reject(error);
};

export default refreshTokeninterceptor;
