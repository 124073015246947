import Vue from "vue";
import axios from "@/axios";
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

const options = {
    // You can set your default options here
};
Vue.use(Toast, options);

export const CaseStore = {

    state: {
        caseData: null,
    },

    mutations: {
        SET_CASE_DETAILS(state, caseData ) {
            state.caseData = caseData;
        },
    },

    getters: {
        getCaseData: state => state.caseData,
    },

    actions: {
        fetchCaseData: async ({ commit }, payload) => {
            if(payload){
                let case_id = payload
                const url = `/case/${case_id}/client`;
                try {
                    let { data } = await axios.get(url);
                    commit('SET_CASE_DETAILS', data.data)
                } catch (error) {
                    // Vue.$toast.warning("case not found!");
                    console.log('error :>> ', error);
                }
            }
        },
    }

};