// import Vue from "vue";
// import Toast from "vue-toastification";
// import "vue-toastification/dist/index.css";
import { FormsPreviewServ } from "./services";

export const SET_FORM_DATA = "FormsPreview/SET_FORM_DATA";
export const SET_FORM_ERROR = "FormsPreview/SET_FORM_ERROR";
export const FETCH_FORM_DATA = "FormsPreview/FETCH_FORM_DATA";

// Vue.use(Toast);

export const FormsPreview = {
    state: {
        formData: null,
        formDataError: false,
    },
    getters: {
        getFormData: (state) => state.formData,
        getFormError: (state) => state.formDataError,
    },
    mutations: {
        [SET_FORM_DATA](state, payload) {
            state.formData = payload;
        },
        [SET_FORM_ERROR](state, payload) {
            console.log('payload-error', payload);
            state.formDataError = payload;
        },
    },
    actions: {
        [FETCH_FORM_DATA]: async ({ commit }, payload) => {
            console.log(payload, "payload");
            const data = await FormsPreviewServ.fetchForms({ type: payload.type });
            if (data?.error) {
                // Vue.$toast.error(data?.errorMessage ? data?.errorMessage : 'Something went wrong');
                commit(SET_FORM_ERROR, data?.error);
            }else {
                commit(SET_FORM_DATA, data);
            }
        },
    },
    // namespaced: true,
};
